import React from "react";
import "./styles.css";
import { SlArrowDown } from "react-icons/sl";
import { FaArrowLeft, FaArrowRight, FaTimes } from "react-icons/fa";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPhotoIndex: null,
    };
  }

  openPhoto(index) {
    this.setState({ selectedPhotoIndex: index });
  }

  closePhoto() {
    this.setState({ selectedPhotoIndex: null });
  }

  prevPhoto() {
    this.setState((prevState) => ({
      selectedPhotoIndex: prevState.selectedPhotoIndex > 0 ? prevState.selectedPhotoIndex - 1 : 9,
    }));
  }

  nextPhoto() {
    this.setState((prevState) => ({
      selectedPhotoIndex: prevState.selectedPhotoIndex < 9 ? prevState.selectedPhotoIndex + 1 : 0,
    }));
  }

  render() {
    let language = this.props.lang;
    const photos = [
      { src: require("../../assets/gallery/LIS.jpg"), title: "LIS - Lisboa", description: language === "en" ? "Lisbon, where it all started" : "Lisboa, onde tudo começou" },
      { src: require("../../assets/gallery/OPO.jpg"), title: "OPO - Porto", description: language === "en" ? "Trip to Porto" : "Viagem ao Porto" },
      { src: require("../../assets/gallery/PMI.jpg"), title: "PMI - Maiorca", description: language === "en" ? "Our trip to Maiorca" : "A nossa viagem a Maiorca" },
      { src: require("../../assets/gallery/LHR.jpg"), title: "LHR - Londres", description: "Trip to London." },
      { src: require("../../assets/gallery/ATH.jpg"), title: "ATH - Athenas", description: "Adventure in Athens" },
      { src: require("../../assets/gallery/ZRH.jpg"), title: "ZRH - Zurique", description: "Our 1st trip together gathering." },
      { src: require("../../assets/gallery/VRA.jpg"), title: "VRA - Varadero", description: "Beach day in Varadero." },
      { src: require("../../assets/gallery/EFL.jpg"), title: "EFL - Kefalonia", description: "Wonderfull Kefalonia." },
      { src: require("../../assets/gallery/BCN.jpg"), title: "BCN - Barcelona", description: "BARCELONAAAA!" },
      { src: require("../../assets/gallery/BOD.jpg"), title: "BOD - Bordeus", description: "Wine tasting in Bordeaux" },
    ];

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let isAndroidPhone = /android/i.test(userAgent);

    return (
      <div>
        {/* Hero Section */}
        <section className={`${isAndroidPhone ? "gallery-welcome-container-android" : ""} gallery-welcome-container`}>
          <div className="gallery-welcome-text">
            <h4 className="headerText" style={{ fontFamily: "CormorantGaramondRegular" }}>
              {language === "en" ? "Our Gallery" : "Nossa Galeria"}
            </h4>
            <SlArrowDown className="down-arrow" />
          </div>
        </section>

        {/* Gallery Grid */}
        <section>
            <p className="gallery-intro-text">
                {language === "en"
                ? "Dear family, below you can see some of our photos."
                : "Querida família, abaixo podem ver algumas das nossas fotos."}
            </p>
          <div className="gallery-grid">
            {photos.map((photo, index) => (
              <div key={index} className="gallery-thumbnail-container">
                <img
                  src={photo.src}
                  alt="Gallery"
                  className="gallery-photo"
                  onClick={() => this.openPhoto(index)}
                />
                  <div className="gallery-title-container">
                    <p className="gallery-title">{photo.title}</p>
                  </div>
              </div>
            ))}
          </div>
        </section>

        {/* Photo Modal */}
        {this.state.selectedPhotoIndex !== null && (
          <div className="gallery-modal">
            <div className="gallery-modal-content">
              <FaTimes className="gallery-close" onClick={() => this.closePhoto()} />
              <FaArrowLeft className="gallery-arrow left" onClick={() => this.prevPhoto()} />
              <img src={photos[this.state.selectedPhotoIndex].src} alt="Selected" className="gallery-modal-img" />
              <p className="gallery-description">{photos[this.state.selectedPhotoIndex].description}</p>
              <FaArrowRight className="gallery-arrow right" onClick={() => this.nextPhoto()} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Gallery;