import React from "react";
import styles from "./burgerMenu.module.css";

import logo from '../../assets/logo.png';

const BurgerMenu = (props) => {
  let homeText = props.lang === "en" ? "Home" : "Início";
  let whereToStayText = props.lang === "en" ? "Where to stay?" : "Onde ficar?";
  // let routePrefix = "";
  let inviteText = props.lang === "en" ? "Invite" : "Convite";
  let giftsText = props.lang === "en" ? "Wedding Gifts" : "Lista de Casamento";
  // let galleryText = props.lang === "en" ? "gallery" : "gallery";
  // let instaPhotos = 'Fotos Instantâneas';
  // Handle language change

  // Get current pathname
  const currentPath = window.location.pathname;

  // Create functions to replace language in the current path
  const switchToEnglish = () => {
    if (!currentPath.includes("/en")) {
      // return currentPath === "/" ? "/home/en" : `${currentPath}/en`;
      return `${currentPath}/en`;
    }
    return currentPath;
  };

  const switchToPortuguese = () => {
    if (currentPath.includes("/en")) {
      return currentPath.replace("/en", "");
    }
    return currentPath;
  };

  return (
    <div
      className={`${
        props.open ? styles.burgerMenuOpen : styles.burgerMenuClosed
      }`}
    >
      <a
        className={`${styles.menuItem} normalFontStyle`}
        href={props.lang === "en" ? `/home/${props.lang}` : "/home"}
      >
        <b>{homeText}</b>
      </a>

      <a
        className={`${styles.menuItem} normalFontStyle`}
        href={props.lang === "en" ? `/invite/${props.lang}` : "/invite"}
      >
        <b>{inviteText}</b>
      </a>

      <a 
        className={`${styles.menuItem} normalFontStyle`}
        href={props.lang === "en" ? `/toStay/${props.lang}` : "/toStay"}
      >
        <b>{whereToStayText}</b>
      </a>

      <a
        className={`${styles.menuItem} normalFontStyle`}
        href={props.lang === "en" ? `/gifts/${props.lang}` : "/gifts"}
      >
        <b>{giftsText}</b>
      </a>

      {/* <a
        className={`${styles.menuItem} normalFontStyle`}
        href={props.lang === "en" ? `/gallery/${props.lang}` : "/gallery"}
      >
        <b>{galleryText}</b>
      </a> */}

      <a
        className={`${styles.menuItem} normalFontStyle`}
        href={
          props.lang === "en" ? `/confirmation/${props.lang}` : "/confirmation"
        }
      >
        <b>RSVP</b>
      </a>

       {/* Add the logo here */}
       <div className={styles.logoContainer}>
        <img src={logo} alt="Logo" className={styles.logo} />
      </div>

      {/* Language switcher */}
      <div className={styles.languageSwitcher}>
        <a href={switchToEnglish()}>
          <button>
            English
          </button>
        </a>
        <a href={switchToPortuguese()}>
          <button>
            Português
          </button>
        </a>
      </div>
    </div>
  );
};

export default BurgerMenu;
